export enum DiagramaticsVersion {
  V1_2_12_1 = 'v1.2.12-1',
  V1_2_12_2_dev = 'v1.2.12-2-dev',
}
export const LATEST_DIAGRAMATICS_VERSION = DiagramaticsVersion.V1_2_12_2_dev;

export enum DiagramaticsMode {
  JS = 'javascript',
  YAML = 'yaml',
}

export enum DiagramaticsImageQuality {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  CUSTOM = 'custom',
}

export const DIAGRAMATICS_IMAGE_QUALITY_SIZE = {
  [DiagramaticsImageQuality.HIGH] : 3000,
  [DiagramaticsImageQuality.MEDIUM] : 2500,
  [DiagramaticsImageQuality.LOW] : 1000,
}

export async function loadDiagramaticsLibrary(version : DiagramaticsVersion) {
  // 
  try {
    switch (version) {
      case DiagramaticsVersion.V1_2_12_1 : return await import('src/assets/lib/diagramatics/diagramatics-ext-1.2.12-1.js');
      case DiagramaticsVersion.V1_2_12_2_dev : return await import('src/assets/lib/diagramatics/diagramatics-ext-1.2.12-2-dev.js');
      default: {
        console.warn('Unknown diagramatics version: ', version);
        return await import('src/assets/lib/diagramatics/diagramatics-ext-1.2.12-2-dev.js');
      }
    }
  } catch (e) {
    console.error('Error loading diagramatics library: ', e);
    return undefined;
  }
}
