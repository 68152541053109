import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { IContentElementPassage, PassageCounterType } from '../../ui-testrunner/element-render-passage/model';
import { generateDefaultElementImage, generateDefaultElementText } from '../item-set-editor/models';
import { ElementType } from 'src/app/ui-testrunner/models';
import { SpecialKeyboardService } from '../special-keyboard.service';
import { FormControl, FormControlDirective, NgModel } from '@angular/forms';
import { TEST_RUNNER_WIDTH_EM } from 'src/app/ui-testrunner/element-render/element-render.component';

export const getWidth = (element: IContentElementPassage) => {
  if(!element.border) {
    return TEST_RUNNER_WIDTH_EM;
  }
  const {padding} = element.border
  return TEST_RUNNER_WIDTH_EM-(isWidthAdjustedForPadding(element) ? (padding * 2) : 0);
}

export const isWidthAdjustedForPadding = (element: IContentElementPassage) => {
  return element.border.isWidthAdjustedForPadding;
}

export const PASSAGE_WIDTH = 38;
export const DEFAULT_PSTYLE_TAB = 4;

@Component({
  selector: 'element-config-passage',
  templateUrl: './element-config-passage.component.html',
  styleUrls: ['./element-config-passage.component.scss']
})
export class ElementConfigPassageComponent implements OnInit, OnDestroy {

  @ViewChild('codeeditor') private codeEditor;
  @Input() element: IContentElementPassage;
  @ViewChild('textBox') textBox:ElementRef

  editorConfig = { 
    lineWrapping: true,
    smartIndent: false,
    indentWithTabs: false,
    lineNumbers: true,
    // mode: 'passage' // todo: implement more precise tagging for xml
    mode: 'xml' 
  };

  counterTypes = [
    PassageCounterType.LINE,
    PassageCounterType.PARAGRAPH,
    PassageCounterType.NONE,
  ]

  codeMirror:{cm: any}; // CodeMirror
  problematicLines:{lineNumber:number}[] = [];

  constructor(
    private authScopeSettings: AuthScopeSettingsService,
    private editingDisabled: EditingDisabledService,
    private specialKeyboard: SpecialKeyboardService
  ) { }

  ngOnInit(): void {

    // todo: shouldnt this be getting re-initialized at each step like this
    // CodeMirror.defineMode("passage", function(config, parserConfig) {
    //   var customOverlay = {
    //       token: function(stream, state) {
    //           var ch;
    //           if (stream.match("<img")) {
    //               while ((ch = stream.next()) != null)
    //                   if (ch == ">" && !stream.eat(">")) break;
    //               return "tag";
    //           }
    //           if (stream.match("<bookmark")) {
    //               while ((ch = stream.next()) != null)
    //                   if (ch == ">" && !stream.eat(">")) break;
    //               if (stream.match(" id=")) return "attribute";
    //               return "tag";
    //           }
    //           while (stream.next() != null && !stream.match("<img", false) && !stream.match("<bookmark", false)) {}
    //           return null;
    //       }
    //   };
    //   return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "text/html"), customOverlay);
    // });

  }

  ngAfterViewInit(){
    const editor = this.codeEditor.codeMirror;
    this.codeMirror = editor.getDoc();
    this.codeMirror.cm.on('change', (instance) => {
      this.detectMaxWidthExceedingLines()
    });
  }

  ngOnDestroy(): void {
  }

  detectMaxWidthExceedingLines(){
    const lines = this.element.text.split('\n');
    this.problematicLines = []
    this.withWidthtester(checkLine => {
      for (let i=0; i<lines.length; i++){
        const line = lines[i]
        const isOverWidth = checkLine(line)
        if (isOverWidth){
          this.problematicLines.push({lineNumber: i+1})
        }
      }
    })
  }

  /**
   * 
   * @param str string to be test for empty
   * @returns true if the string is a blank line
   */
  isEmptyLine(str: string): boolean{
    return !str || str.trim()==="";
  }

  /**
   * 
   * @param lines list of lines
   * @param index index where string needs to be added
   * @param strToAdd string to be added
   * @description receives an input of string and index and adds it to the lines array at the given index.
   */
  addtoLine(lines:string[], index: number, strToAdd:string): void{
    if (this.isEmptyLine(lines[index])){
      // if the line does not exist and is intentionally blank, create new line
      lines.splice(index, 0, strToAdd)
    }else{
      // line exists, add to line
      lines[index] = strToAdd + " " + lines[index]
    }
  }



  autoReflow(){
    const lines = this.element.text.split('\n');
    this.withWidthtester(checkLine => {
      let index = 0

      // we will go through line by line, and attempt to fix each one
      while(index < lines.length){
        let isOverWidth = checkLine(lines[index])
        if (!isOverWidth){  // if the line is not over width then we can go to the next line
          index++
          continue
        }

        const lineWords = lines[index].trim().split(' ');

        // keep removing the last word and adding it to the next line and check if the line is valid
        while(lineWords.length > 1 && isOverWidth){
          let lastWord = lineWords.pop()
          this.addtoLine(lines, index+1,lastWord)

          const newLine = lineWords.join(" ")
          isOverWidth = checkLine(newLine)
        }
        
        // if there is a line where there is possibly an image taking up majority of the line space
        // in that case, we want to give the word its own line and test it.
        if(lineWords.length <= 1 && isOverWidth) {
          const testWord = lineWords[0]
          if (checkLine(testWord)){ 
            // if our test result is positive, the word is not the issue, there are other things on the line causing 
            // the overflow. add the word to the next line
            this.addtoLine(lines, index+1, testWord)
            lineWords.pop()
          }
        }

        // done with the line
        lines[index] = lineWords.join(" ")
        index++
      }
    })
    this.element.text = lines.join('\n')
    this.detectMaxWidthExceedingLines()
  }

  getWidth() {
    return getWidth(this.element);
  }

  private withWidthtester( runChecks:(checkLine:(line:string)=>boolean)=>void ){
    const testContainer = document.createElement('div');
    document.body.appendChild(testContainer);
    testContainer.style.width = `${this.getWidth()}em`
    testContainer.style.position = 'fixed';
    testContainer.style.visibility = 'hidden';
    testContainer.style.whiteSpace = 'pre';
    testContainer.style.padding = '0em';
    testContainer.innerText = '-'
    const maxWidthPx = testContainer.clientWidth;
    testContainer.style.width = 'auto'
    const checkLine = (line:string) => {
      testContainer.innerText = line
      const widthPx = testContainer.clientWidth;
      return (widthPx > maxWidthPx)
    }
    runChecks(checkLine);
    document.body.removeChild(testContainer);
  }


  getNextImageId(){
    let id = 1;
    for (let image of this.element.images){
      if (+image.id >= id){
        id = (+image.id)+1
      }
    }
    return id
  }

  getNextParagraphStyleId(){
    let id = 1;
    for (let ps of this.element.paragraphStyles){
      if (+ps.id >= id){
        id = (+ps.id)+1
      }
    }
    return id
  }

  insertParagraphStyle(){
    if (!this.element.paragraphStyles){
      this.element.paragraphStyles = [];
    }
    const id = this.getNextParagraphStyleId();
    this.element.paragraphStyles.push({
      id,
      tabs: DEFAULT_PSTYLE_TAB
    })
  }

  insertImage(){
    if (!this.element.images){
      this.element.images = [];
    }
    const id = this.getNextImageId();
    this.element.images.push({
      id,
      el: generateDefaultElementImage('image'),
      padding: 0.0
    })
  }

  updateChangeCounter(){
    this.element._changeCounter = (this.element._changeCounter || 0) +1
  }

  onImageDrop($event){
    
  }

  removeParagraphStyle(id: number){
    this.element.paragraphStyles = this.element.paragraphStyles.filter(entry => entry.id !== id);
  }

  removeImage(id: number){
    this.element.images = this.element.images.filter(entry => entry.id !== id);
    this.updateChangeCounter()
  }

  handleFocus($event: any) {
    if($event){
      this.specialKeyboard.currentElement.next({element: this.element, htmlElement:this.codeMirror.cm.display.input.textarea, formControl: undefined, codeMirror: this.codeMirror});
    }
    if(!$event) {
      this.specialKeyboard.focusOut();
    }
  }
}
