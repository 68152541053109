export const indexOf = (arr:any[], t:any) => {
    let i = -1;
    arr.forEach((_t, _i) => {
      if (_t === t){
        i = _i;
      }
    });
    return i;
  }
  

export const mapToJson = (map) =>  {
    return JSON.stringify([...map]);
}

/**
 * Inside the input object, find a sub-object with some key-value pair
 * @param obj A neted object
 * @param targetKey The key some sub-object should have, e.g. `entryId`
 * @param targetVal The value of the jey some sub-object should have, e.g. `3`
 * @returns The sub-object that fits the criteria, i.e. has entryId = 3
 */
export const deepFind = (obj, targetKey, targetVal) => {
  if (!obj || typeof obj !== 'object') return null;
  if (obj[targetKey] === targetVal) return obj;
  return Object.values(obj).reduce((acc, val) => acc || deepFind(val, targetKey, targetVal), null);
}