export enum CheckingCaptionStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
  CHECKING = 'CHECKING',
  DEFAULT = 'DEFAULT',
}

export enum NetworkProtocol {
  HTTP = 'HTTP',
  WSS = 'WSS',
}

export interface DomainExclusionCheck {
  id: string,
  heading: string,
  status: CheckingCaptionStatus,
  failMessage: string,
  url: string,
  protocol: NetworkProtocol,
  signed?: boolean,
  bucket?: string,  // default bucket 'storage.mathproficiencytest.ca'
  filePath?: string,
}

export const DOMAIN_EXCLUSION_CHECKS : DomainExclusionCheck[] = [
  {
    id: 'board_tech_redi_txt_v2_d1',
    heading: 'board_tech_redi_txt_v2_d1', // stripe.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://js.stripe.com/v3/fingerprinted/js/m-outer-8cb24ab2d649fd36a488d04d8c457933.js',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d2',
    heading: 'board_tech_redi_txt_v2_d2', // vretta.com
    status: CheckingCaptionStatus.DEFAULT, 
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://eqao.vretta.com',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d3',
    heading: 'board_tech_redi_txt_v2_d3', // *.stripe.com
    status: CheckingCaptionStatus.DEFAULT, 
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://js.stripe.com/v3/fingerprinted/js/m-outer-8cb24ab2d649fd36a488d04d8c457933.js',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d4',
    heading: 'board_tech_redi_txt_v2_d4', // *.vretta.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://eqao.vretta.com',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d5',
    heading: 'board_tech_redi_txt_v2_d5', // cdnjs.cloudflare.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d7',
    heading: 'board_tech_redi_txt_v2_d7', // d3azfb2wuqle4e.cloudfront.net 
    status: CheckingCaptionStatus.DEFAULT, 
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/alt_version_logo_2/1669839240591/alt_version_logo_2.png',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d8',
    heading: 'board_tech_redi_txt_v2_d8', // d3f6c695rnoy7r.cloudfront.net
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://d3f6c695rnoy7r.cloudfront.net/lib/zwibbler/stamps/BlackCircle.png',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d9',
    heading: 'board_tech_redi_txt_v2_d9', // fonts.googleapis.com 
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d10',
    heading: 'board_tech_redi_txt_v2_d10', // fonts.gstatic.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://fonts.gstatic.com/s/sourcesanspro/v22/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d11',
    heading: 'board_tech_redi_txt_v2_d11', // maxcdn.bootstrapcdn.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'https://maxcdn.bootstrapcdn.com',
    protocol: NetworkProtocol.HTTP,
  },
  {
    id: 'board_tech_redi_txt_v2_d12',
    heading: 'board_tech_redi_txt_v2_d12', // mnomt58qw8.execute-api.ca-central-1.amazonaws.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'wss://mnomt58qw8.execute-api.ca-central-1.amazonaws.com/production',
    protocol: NetworkProtocol.WSS,
  },
  {
    id: 'board_tech_redi_txt_v2_d13',
    heading: 'board_tech_redi_txt_v2_d13', // mqvurtmevc.execute-api.ca-central-1.amazonaws.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: 'wss://mqvurtmevc.execute-api.ca-central-1.amazonaws.com/production',
    protocol: NetworkProtocol.WSS,
  },
  // {
  //   id: 'board_tech_redi_txt_v2_d14',
  //   heading: 'board_tech_redi_txt_v2_d14', // s3.ca-central-1.amazonaws.com
  //   status: CheckingCaptionStatus.DEFAULT,
  //   failMessage: 'board_tech_redi_check_fail',
  //   url: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/landing_2-splash/1666377222603/landing_2-splash.png',
  //   protocol: NetworkProtocol.HTTP,
  // },
  {
    id: 'board_tech_redi_txt_v2_d15',
    heading: 'board_tech_redi_txt_v2_d15', //d3c1mbjtikq6ue.cloudfront.net
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: '',
    protocol: NetworkProtocol.HTTP,
    signed: true,
    bucket: '',  // default bucket 'storage.mathproficiencytest.ca'
    filePath: 'style_profile/gr9styleprofile.json',
  },
  {
    id: 'board_tech_redi_txt_v2_d16',
    heading: 'board_tech_redi_txt_v2_d16', //eqao-secure.s3.ca-central-1.amazonaws.com
    status: CheckingCaptionStatus.DEFAULT,
    failMessage: 'board_tech_redi_check_fail',
    url: '',
    protocol: NetworkProtocol.HTTP,
    signed: true,
    bucket: 'eqao-secure',
    filePath: 'boards/seb/0054_14.seb',
  }
]

export const DIST_ADMIN_TECH_READ_CHECKLIST = [
  {
    id: 'board_tech_redi_1',
    caption: ('board_tech_redi_txt_1'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    links: []
  },
  {
    id: 'board_tech_redi_txt_2',
    caption: 'board_tech_redi_txt_2_v2',
    links: [
      {
        slug: 'DOMAIN_EXCLUSION_TEST',
        isSecure: true,  // mean don't use default setting
        linkCaption: 'tech_redi_test_domain_exclusion',
      },
    ],
    captionChecked : false,
    captionChecks: DOMAIN_EXCLUSION_CHECKS,
  },
  {
    id: 'board_tech_read_check_dev',
    caption: 'board_tech_read_check_dev_1', 
    links: [
    {
      linkUrl: 'download_seb_windows_url',
      linkCaption: 'download_seb_windows'
    },
    {
      linkUrl: 'download_seb_mac_url',
      linkCaption: 'download_seb_mac'
    },
    {
      slug: 'SEB_CONFIG',
      isSecure: true,
      linkCaption: 'tech_redi_download_seb_cf', 
      descSlug: 'txt_dl_seb_config'
    },
    {
      slug: 'SEB_CONFIG_PASS',
      isSecure: true,
      linkCaption: 'tech_redi_download_seb_pass',
    },
    {
      linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
      linkCaption: 'tech_redi_device_kiosk_ext',
    }, 
    {
      slug: 'KIOSK_POLICY',
      isSecure: true,
      linkCaption: 'tech_redi_device_kiosk_cfg',
      descSlug: 'txt_dl_kiosk_policy'
    }, 
    {
      slug: 'KIOSK_POLICY_CREDS',
      isSecure: true,
      linkCaption: 'tech_redi_device_kiosk_pass',
    }
  ],
    selectionCaption:[
      {
        heading:'',
        selectionOptions: [
          {slug:'board_tech_redi_3', caption:'board_tech_redi_txt_3'},
        ]
      },
      {
      heading:'board_tech_redi_txt_18',
      selectionOptions: [
      {slug:'board_tech_redi_19', caption:'board_tech_redi_txt_19'},
      {slug:'board_tech_redi_22', caption:'board_tech_redi_txt_22'},
    
    ]
    },
    {
      heading:'board_tech_redi_txt_20',
      selectionOptions: [
      {slug:'board_tech_redi_21', caption:'board_tech_redi_txt_21'},
      {slug:'board_tech_redi_23', caption:'board_tech_redi_txt_23'},
      {slug:'board_tech_redi_24', caption:'board_tech_redi_txt_24'},
    ]
    }
  ], 
  },
  // {
  //   id: 'board_tech_redi_3',
  //   caption: 'board_tech_redi_txt_3', 
  //   links: [{
  //     linkUrl: 'https://safeexambrowser.org/download_en.html',
  //     linkCaption: 'tech_redi_download_seb_sw'
  //   }]
  // },
  // {
  //   id: 'board_tech_redi_4',
  //   caption: 'board_tech_redi_txt_4',
  //   links: [
  //     {
  //       slug: 'SEB_CONFIG',
  //       isSecure: true,
  //       linkCaption: 'tech_redi_download_seb_cf', 
  //       descSlug: 'txt_dl_seb_config'
  //     },{
  //       slug: 'SEB_CONFIG_PASS',
  //       isSecure: true,
  //       linkCaption: 'tech_redi_download_seb_pass',
  //     }
  //   ]
  // },
  // {
  //   id: 'board_tech_redi_5',
  //   caption: 'board_tech_redi_txt_5', 
  //   links: [{
  //     linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
  //     linkCaption: 'tech_redi_device_kiosk_ext',
  //   }, {
  //     slug: 'KIOSK_POLICY',
  //     isSecure: true,
  //     linkCaption: 'tech_redi_device_kiosk_cfg',
  //     descSlug: 'txt_dl_kiosk_policy'
  //   }, {
  //     slug: 'KIOSK_POLICY_CREDS',
  //     isSecure: true,
  //     linkCaption: 'tech_redi_device_kiosk_pass',
  //   }]
  // },
  {
      id: 'board_tech_redi_6',
      caption: ('board_tech_redi_txt_6'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_7',
      caption: ('board_tech_redi_txt_7'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_8',
      caption: ('board_tech_redi_txt_8'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_9',
      caption: ('board_tech_redi_txt_9'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    },
    {
      id: 'board_tech_redi_10',
      caption: ('board_tech_redi_txt_10'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_11',
      caption: ('board_tech_redi_txt_11'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_12',
      caption: ('board_tech_redi_txt_12'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_13',
      caption: ('board_tech_redi_txt_13'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_14',
      caption: ('board_tech_redi_txt_14'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_15',
      caption: ('board_tech_redi_txt_15'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_16',
      caption: ('board_tech_redi_txt_16'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_17',
      caption: ('board_tech_redi_txt_17'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    }


]

export const DIST_ADMIN_TECH_READ_CHECKLIST_PJ = [
  {
    id: 'board_tech_redi_1',
    caption: ('board_tech_redi_txt_1'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    links: []
  },
  {
    id: 'board_tech_redi_txt_2',
    caption: 'board_tech_redi_txt_2_v2',
    links: [
      {
        slug: 'DOMAIN_EXCLUSION_TEST',
        isSecure: true,  // mean don't use default setting, it define in html by iteslef. 
        linkCaption: 'tech_redi_test_domain_exclusion',
      },
    ],
    captionChecked : false,
    captionChecks: DOMAIN_EXCLUSION_CHECKS,
  },
  {
    id: 'board_tech_read_check_dev',
    caption: 'board_tech_read_check_dev_1', 
    links: [
    {
      linkUrl: 'download_seb_windows_url',
      linkCaption: 'download_seb_windows'
    },
    {
      linkUrl: 'download_seb_mac_url',
      linkCaption: 'download_seb_mac'
    },
    {
      slug: 'SEB_CONFIG',
      isSecure: true,
      linkCaption: 'tech_redi_download_seb_cf', 
      descSlug: 'txt_dl_seb_config'
    },{
      slug: 'SEB_CONFIG_PASS',
      isSecure: true,
      linkCaption: 'tech_redi_download_seb_pass',
    },
    {
      linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
      linkCaption: 'tech_redi_device_kiosk_ext',
    }, {
      slug: 'KIOSK_POLICY',
      isSecure: true,
      linkCaption: 'tech_redi_device_kiosk_cfg',
      descSlug: 'txt_dl_kiosk_policy'
    }, {
      slug: 'KIOSK_POLICY_CREDS',
      isSecure: true,
      linkCaption: 'tech_redi_device_kiosk_pass',
    }
  ],
    selectionCaption:[
      {
        heading:'',
        selectionOptions: [
          {slug:'board_tech_redi_25', caption:'board_tech_redi_txt_25'},
        ]
      },
      {
        heading:'',
        selectionOptions: [
          {slug:'board_tech_redi_3', caption:'board_tech_redi_txt_3'},
        ]
      },
      {
      heading:'board_tech_redi_txt_18',
      selectionOptions: [
      {slug:'board_tech_redi_19', caption:'board_tech_redi_txt_19'},
      {slug:'board_tech_redi_22', caption:'board_tech_redi_txt_22'},
    
    ]
    },
    {
      heading:'board_tech_redi_txt_20',
      selectionOptions: [
      {slug:'board_tech_redi_21', caption:'board_tech_redi_txt_21'},
      {slug:'board_tech_redi_23', caption:'board_tech_redi_txt_23'},
      {slug:'board_tech_redi_24', caption:'board_tech_redi_txt_24'},
    ]
    }
  ], 
  },
  // {
  //   id: 'board_tech_redi_3',
  //   caption: 'board_tech_redi_txt_3', 
  //   links: [{
  //     linkUrl: 'https://safeexambrowser.org/download_en.html',
  //     linkCaption: 'tech_redi_download_seb_sw'
  //   }]
  // },
  // {
  //   id: 'board_tech_redi_4',
  //   caption: 'board_tech_redi_txt_4',
  //   links: [
  //     {
  //       slug: 'SEB_CONFIG',
  //       isSecure: true,
  //       linkCaption: 'tech_redi_download_seb_cf', 
  //       descSlug: 'txt_dl_seb_config'
  //     },{
  //       slug: 'SEB_CONFIG_PASS',
  //       isSecure: true,
  //       linkCaption: 'tech_redi_download_seb_pass',
  //     }
  //   ]
  // },
  // {
  //   id: 'board_tech_redi_5',
  //   caption: 'board_tech_redi_txt_5', 
  //   links: [{
  //     linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
  //     linkCaption: 'tech_redi_device_kiosk_ext',
  //   }, {
  //     slug: 'KIOSK_POLICY',
  //     isSecure: true,
  //     linkCaption: 'tech_redi_device_kiosk_cfg',
  //     descSlug: 'txt_dl_kiosk_policy'
  //   }, {
  //     slug: 'KIOSK_POLICY_CREDS',
  //     isSecure: true,
  //     linkCaption: 'tech_redi_device_kiosk_pass',
  //   }]
  // },
  {
      id: 'board_tech_redi_6',
      caption: ('board_tech_redi_txt_6'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_7',
      caption: ('board_tech_redi_txt_7'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_8',
      caption: ('board_tech_redi_txt_8'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_9',
      caption: ('board_tech_redi_txt_9'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    },
    {
      id: 'board_tech_redi_10',
      caption: ('board_tech_redi_txt_10'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_11',
      caption: ('board_tech_redi_txt_11'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_12',
      caption: ('board_tech_redi_txt_12'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_13',
      caption: ('board_tech_redi_txt_13'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      confirm: true
    },
    {
      id: 'board_tech_redi_14',
      caption: ('board_tech_redi_txt_14'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_15',
      caption: ('board_tech_redi_txt_15'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_16',
      caption: ('board_tech_redi_txt_16'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    },
    {
      id: 'board_tech_redi_17',
      caption: ('board_tech_redi_txt_17'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
      links: []
    }


]