import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { AudioBufferService, VoiceSound } from '../audio-buffer.service';
import { DisplayMode } from '../element-render-audio/model';
import { memo } from '../element-render-video/element-render-video.component';
import { UrlLoaderService } from '../url-loader.service';
import { TextToSpeechService } from '../text-to-speech.service';

export interface IAudioSrcInfo {
  containing_item_id?: number,
  url: string, 
}

@Component({
  selector: 'render-audio',
  templateUrl: './render-audio.component.html',
  styleUrls: ['./render-audio.component.scss']
})
export class RenderAudioComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() url:string;
  @Input() audioID?:string;
  @Input() preventOverload:boolean = false;
  @Input() isDisabled:boolean = false;
  @Input() hasControls:boolean = false;
  @Input() trigger:Subject<boolean>;
  @Input() isTriggerDisabled:boolean;
  @Input() startTime:number;
  @Input() forceNativePlayer:boolean;
  @Input() mode:DisplayMode;
  @Input() isQuestionAudio:boolean;
  @Input() containingItemId:number;
  @Output() play:EventEmitter<IAudioSrcInfo> = new EventEmitter();
  @Output() onPlay:EventEmitter<IAudioSrcInfo> = new EventEmitter();
  @Output() progress:EventEmitter<IAudioSrcInfo> = new EventEmitter();
  @Output() end:EventEmitter<IAudioSrcInfo> = new EventEmitter();
  @Output() timeUpdate:EventEmitter<IAudioSrcInfo> = new EventEmitter();
  @Output() canStartPlaying:EventEmitter<IAudioSrcInfo> = new EventEmitter();

  @ViewChild('audioPlayer') audioPlayer: ElementRef

  sound:VoiceSound;
  audioSources = new Map();
  public isPlaying: boolean = false;
  triggerSub:Subscription;
  
  __componentId = Math.random();

  constructor(
    private textToSpeech:TextToSpeechService,
    private urlLoader: UrlLoaderService,
    private audioBuffer: AudioBufferService,
    private whiteLabel: WhitelabelService
  ) { }

  

  ngOnInit(): void { }

  ngAfterViewInit(){
    // if (!this.controlsVisible()){
      if (this.triggerSub){
        this.triggerSub.unsubscribe();
      }
      if (this.trigger){
        this.triggerSub = this.trigger.subscribe(this.onTriggerPlay)
      }
      if (this.sound){
        this.sound.destroy();
      }

      this.sound = new VoiceSound(this.audioPlayer.nativeElement, this.audioBuffer, this.isQuestionAudio);
      this.sound.getElement().addEventListener('pause', this.triggerDonePlaying);
      //this.sound.getElement().addEventListener('ended', this.onEnded);
    // }
  }

  private getAudioSrcInfo() : IAudioSrcInfo{
    return {
      url: this.url,
      containing_item_id: this.containingItemId,
    }
  }

  triggerDonePlaying = (): void => {
    // If we already triggered, skip
    if (!this.isPlaying)
      return;

    this.donePlaying();
  }

  ngOnDestroy() {
    if (this.triggerSub){
      this.triggerSub.unsubscribe();
    }
    if (this.sound){
      this.sound.removeFromBuffer()
      //this.sound.getElement().removeEventListener('ended', this.onEnded);
    }
    if (this.audioBuffer.activeSound === this.sound){
      this.audioBuffer.activeSound = null;
    }
  }
  // onEnded = () => this.end.emit();

  onTriggerPlay = (isActive:boolean) => {
    if (this.preventOverload){
      if (this.audioBuffer.activeSound){
        return
      }
    }
    if (!this.sound){
      return;
    }
    if (this.isDisabled){
      return;
    }
    if (isActive && !this.isTriggerDisabled){
      this.textToSpeech.onPlay.next(this.getAudioSrcInfo());
      this.onPlay.emit(this.getAudioSrcInfo());
      this.sound.play();
      this.isPlaying = true;
    }
  }

  emitPlay() {
    // console.log("in emit play", this.__componentId)
    this.play.emit(this.getAudioSrcInfo())
    this.isPlaying = true;
  }

  private isSafari(){
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }

  emitCanStartPlaying() {
    this.canStartPlaying.emit(this.getAudioSrcInfo())
  }

  loadedMetaData() {
    if (this.isSafari()) {
      this.canStartPlaying.emit(this.getAudioSrcInfo())
    }
  }

  emitEnd() {
    this.end.emit(this.getAudioSrcInfo())
  }

 private donePlaying(): void {
    this.isPlaying = false;
    if (this.emitEnd) this.emitEnd();
  }

  sanitizeUrl(url:string){
    return this.urlLoader.sanitize(this.url);
  }

  stopProp($event) {
    $event.stopPropagation()
  }

  getAudioSources(){
    // setTimeout(()=>{
    //   console.log(this.audioSources);
    // }, 500)
    return memo(this.audioSources, this.url, url => {
      const sanitized = this.urlLoader.sanitize(url)
      // console.log(url);
      return [{ src: url, type: 'audio/mp3', }]
    });
  }

  controlsVisible() {
    return this.hasControls;
  }

  isOneButton() {
    return !this.forceNativePlayer && this.whiteLabel.getSiteFlag('IS_BCED');
  }

  onTimeUpdate() {
    this.timeUpdate.emit(this.getAudioSrcInfo());
  }

  isCustomMode() {
    return !this.mode || this.mode === DisplayMode.CUSTOM
  }

  isNormalMode() {
    return this.mode === DisplayMode.NORMAL
  }
}
