import { ElementType, IContentElement, IVoiceover } from "../../ui-testrunner/models";
import { IContentElementGroup } from "src/app/ui-testrunner/element-render-grouping/model";

// List which element types need a score matrix (which was implemented)
export const scoreMatrixElementTypes: (ElementType | string)[] = [
  ElementType.GROUPING
]

export enum MatrixHeaderType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  MATH = "MATH"
}
export interface IMatrixHeader {
  type: MatrixHeaderType,
  content: string
}
export interface IMatrixColHeader extends IMatrixHeader {};
export interface IMatrixRowHeader extends IMatrixHeader {
  key_id?: string|number;
}

export interface IScoreMatrix {
  columns: IMatrixColHeader[],
  rows: IMatrixRowHeader[],
  values: number[][]
}

/** Get matrix rows and columns for a grouping element */
const getMatrixDimensionsGrouping = (element:IContentElementGroup): {rows: IMatrixRowHeader[], columns: IMatrixColHeader[]} => {
  const rows:IMatrixRowHeader[] = [];
  const columns:IMatrixColHeader[] = [];
  // Targets as columns - Only use the ID of the target
  element.targets.forEach(target => {
    const newColumn: IMatrixColHeader = {
      type: MatrixHeaderType.TEXT,
      content: ''+target.id
    }
    columns.push(newColumn)
  })
  // Draggables as rows
  element.draggables.forEach(draggable => {
    let {type, content} = elementToMatrixHeader(draggable.element)
    if (draggable.element.elementType == ElementType.TABLE){
      type = MatrixHeaderType.TEXT,
      // If a table is a draggable option, assume it will have a label and only use that
      content = draggable.label
    }
    const newRow: IMatrixRowHeader = {
      type,
      content,
      key_id: draggable.key_id
    }
    rows.push(newRow)
  })
  return {rows, columns}
}

/**
 * Transform an element into content to be rendered inside the row/column header cell
 * Can be reused by different `getMatrixDimensions...` functions
 */
const elementToMatrixHeader = (element:IContentElement) : IMatrixHeader => {
  let type, content;
  switch(element.elementType){
    case ElementType.TEXT:
      type = MatrixHeaderType.TEXT;
      content = element.caption;
      break;
    case ElementType.IMAGE:
      type = MatrixHeaderType.IMAGE;
      content = element.images.default.image.url;
      break;
    case ElementType.MATH:
      type = MatrixHeaderType.MATH;
      content = element.latex;
      break;
    default:
      type = MatrixHeaderType.TEXT;
      content = ""
      break;
  }
  return {type, content}
}


/** Obtain the matrix rows (options) and columns (targets) for a block element */
export const getMatrixDimensions = (element:IContentElement) : {rows: IMatrixRowHeader[], columns: IMatrixColHeader[]} => {
  let dimensions = {columns: [], rows: []};
  switch(element.elementType){
    case ElementType.GROUPING: 
      dimensions = getMatrixDimensionsGrouping (<IContentElementGroup> element);
      break;
    //TODO: Implement functions for other element types
  }
  return dimensions;
}


/**
 * Refresh the score matrix based on the content of the element
 * For any unchanged rows or columns, preserve the old values - otherwise use blank values
 * @param element The block element which contains the matrix (is updated in-place)
 */
export const refreshScoreMatrix = (element:IContentElement) => {

  // Element must qualify to have a matrix, otherwise skip
  if (!scoreMatrixElementTypes.includes(element.elementType)) {
    return
  }

  // If no matrix exists on the block yet, make a blank one
  if (!element.scoreMatrix){
    element.scoreMatrix = {
      rows: [],
      columns: [],
      values: []
    }
  }

  // Get the previous matrix content
  const prevColumns = element.scoreMatrix.columns
  const prevRows = element.scoreMatrix.rows
  const prevValues = element.scoreMatrix.values

  // Generate matrix columns and rows based on the element
  const {columns, rows} = getMatrixDimensions(element)

  // Maps which rows and columns did not change from the previous refresh
  const rowMatch = {}, columnMatch = {}
  rows.forEach((row, index) => {
    const prevIndexMatch = prevRows.findIndex(prevRow => prevRow.type == row.type && prevRow.content == row.content && prevRow.key_id == row.key_id )
    if (prevIndexMatch != -1) {
      rowMatch[index] = prevIndexMatch
    }
  })
  columns.forEach((col, index) => {
    const prevIndexMatch = prevColumns.findIndex(prevCol => prevCol.type == col.type && prevCol.content == col.content)
    if (prevIndexMatch != -1) {
      columnMatch[index] = prevIndexMatch
    }
  })

  // Make the new 2D array of values - if the row and column was in the previous matrix use existing value, otherwise default to ""
  const values = []
  rows.forEach((row, rowIndex) => {
    const prevRowIndex = rowMatch[rowIndex]
    const valuesForRow = []
    columns.forEach((col, colIndex) => {
      const prevColIndex = columnMatch[colIndex]
      if (prevRowIndex !== undefined && prevColIndex !== undefined){
        valuesForRow.push(prevValues[prevRowIndex][prevColIndex])
      } else {
        valuesForRow.push("")
      }
    })
    values.push(valuesForRow)
  })

  // Update to new matrix
  element.scoreMatrix.rows = rows;
  element.scoreMatrix.columns = columns;
  element.scoreMatrix.values = values;
}


