<div [ngSwitch]="contentElement.elementType" class="element-render-printable" [class.is-selected-edit]="isSelectedEdit()" [style.border]="getBorder()">
  <div *ngSwitchCase="ElementType.AUDIO">         <element-render-audio           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [allowTranscripts]="allowTranscripts" [allowPlaybackSpeed]="allowAudioPlaybackSpeed"></element-render-audio></div>
  <div *ngSwitchCase="ElementType.CAMERA">        <element-render-camera          [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-camera></div>
  <div *ngSwitchCase="ElementType.CANVAS">        <element-render-canvas          [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-canvas> </div>
  <div *ngSwitchCase="ElementType.INSERTION">     <element-render-insertion       [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-insertion> </div>
  <div *ngSwitchCase="ElementType.CUSTOM_MCQ">    <element-render-custom-mcq      [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-custom-mcq></div> 
  <div *ngSwitchCase="ElementType.DND">           <element-render-dnd             [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter" ></element-render-dnd></div>
  <div *ngSwitchCase="ElementType.MOVEABLE_DND">  <element-render-moveable-dnd    [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter" ></element-render-moveable-dnd></div>
  <div *ngSwitchCase="ElementType.GRAPHICS">      <element-render-graphics        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-graphics></div>
  <div *ngSwitchCase="ElementType.GRAPHING">      <element-render-graphing        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-graphing></div>
  <div *ngSwitchCase="ElementType.GROUPING">      <element-render-grouping        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-grouping></div>
  <div *ngSwitchCase="ElementType.PASSAGE">       <element-render-passage         [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-passage></div>
  <div *ngSwitchCase="ElementType.TEMPLATE">      <element-render-template        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-template></div>
  <div *ngSwitchCase="ElementType.HOTSPOT">       <element-render-hotspot         [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-hotspot></div>
  <div *ngSwitchCase="ElementType.HOTTEXT">       <element-render-hottext         [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-hottext></div>
  <div *ngSwitchCase="ElementType.FRAME">         <element-render-frame           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [allowSubtitles]="allowSubtitles" [allowTranscripts]="allowTranscripts" [allowVideoPlaybackSpeed]="allowVideoPlaybackSpeed" [allowAudioPlaybackSpeed]="allowAudioPlaybackSpeed"></element-render-frame> </div>
  <div *ngSwitchCase="ElementType.IFRAME">        <element-render-iframe          [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-iframe></div>
  <div *ngSwitchCase="ElementType.IMAGE">         <element-render-image           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-image></div>
  <div *ngSwitchCase="ElementType.INPUT">         <element-render-input           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState"  [opinionEssayEditable]="opinionEssayEditable" [textInputChange]="textInputChange" [fromIssueReviewer]="fromIssueReviewer" [cancelOverrideResponse]="cancelOverrideResponse" [currentQuestionMeta]="currentQuestionMeta"></element-render-input></div>
  <div *ngSwitchCase="ElementType.DOC_LINK">      <element-render-doc-link        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-doc-link></div>
  <div *ngSwitchCase="ElementType.MATCHING">      <element-render-matching        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-matching></div>
  <div *ngSwitchCase="ElementType.MATH">          <element-render-math            [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-math></div>
  <div *ngSwitchCase="ElementType.MCQ">           <element-render-mcq             [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [frameWorkTags]="frameWorkTags"></element-render-mcq></div>
  <div *ngSwitchCase="ElementType.MIC">           <element-render-mic             [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-mic></div>
  <div *ngSwitchCase="ElementType.ORDER">         <element-render-order           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter" ></element-render-order></div>
  <div *ngSwitchCase="ElementType.READER">        <element-render-reader          [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-reader> </div>
  <div *ngSwitchCase="ElementType.SOLUTION">      <element-render-solution        [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-solution> </div>
  <div *ngSwitchCase="ElementType.RESULTS_PRINT"> <element-render-results-print   [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-results-print></div>
  <div *ngSwitchCase="ElementType.SELECT_TABLE">  <element-render-selection-table [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-selection-table> </div>
  <div *ngSwitchCase="ElementType.TABLE">         <element-render-table           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-table> </div>
  <div *ngSwitchCase="ElementType.TEXT">          <element-render-text            [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-text> </div>
  <div *ngSwitchCase="ElementType.SELECT_TEXT">   <element-render-select-text     [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-select-text> </div>
  <div *ngSwitchCase="ElementType.TEXT_LINK">     <element-render-text-link       [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-text-link> </div>
  <div *ngSwitchCase="ElementType.UPLOAD">        <element-render-upload          [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-upload></div>
  <div *ngSwitchCase="ElementType.VIDEO">         <element-render-video           [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [allowSubtitles]="allowSubtitles" [allowTranscripts]="allowTranscripts" [allowPlaybackSpeed]="allowVideoPlaybackSpeed"></element-render-video></div>
  <div *ngSwitchCase="ElementType.VALIDATOR">     <element-render-validator       [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-validator> </div>
  <div *ngSwitchCase="ElementType.CUSTOM_INTERACTION"><element-render-custom-interaction [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-custom-interaction></div>
  <div *ngSwitchCase="ElementType.BOOKMARK_LINK">     <element-render-bookmark-link       [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" ></element-render-bookmark-link> </div>
  <div *ngSwitchCase="ElementType.STATIC_DIAGRAM">    <element-render-static-diagram      [element]="contentElement" [isLocked]="isLocked" [questionPubSub]="questionPubSub"  [questionState]="questionState" [changeCounter]="contentElement._changeCounter"></element-render-static-diagram></div>
</div>
