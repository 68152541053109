import { escapeRegEx } from "src/app/core/styleprofile.service"
import { SpecialCharacter } from "src/app/ui-item-maker/special-character-keyboard/special-character-keyboard.component"

export enum EInsertType {
    BEG_END = 'beg_end',
    REPLACE = 'replace'
}

const isEmptyString = (str:string) => {
    return !str || str==''
}


const isIESupport = () => {
    return document['selection'] != null
}

const isMozilla = (myField) => {
    return myField.selectionStart || myField.selectionStart == '0'
}

export const deleteSurrounding = (myField, beginning, end) => {
    if (beginning == null || end == null || beginning == '' || end == '') {
        return {val: myField.value, newCursorLoc: undefined}
    }

    if (isIESupport()) {
        const sel  = document['selection'].createRange();
    } else if (isMozilla(myField)) {
        const startPos = myField.selectionStart
        const endPos = myField.selectionEnd
        const val = myField.value
        let beforeVal = val.substring(0, startPos)
        let afterVal = val.substring(endPos)
        let indexOfStart = beforeVal.lastIndexOf(beginning)
        let indexOfEnd = afterVal.indexOf(end)
        //Special case for single star
        if (beginning=='*' && end=='*') {
            indexOfEnd = afterVal.search(/[^*]([*])[^*]/gm)
            const backwardBefore = beforeVal.split("").reverse().join("")
            indexOfStart = backwardBefore.search(/[^*]([*])[^*]/gm)
            if (indexOfEnd!=-1) {
                indexOfEnd++
            }
            if (indexOfStart!=-1) {
                indexOfStart++
            }
            if (indexOfEnd==-1) {
                indexOfEnd = afterVal.indexOf("***")
            }
            if (indexOfStart==-1) {
                indexOfStart = backwardBefore.indexOf("***")
            }
            const checkEndsForStar = (substr, index) =>{
                if (index==-1 && substr.length>0) {
                    if (substr.charAt(0)=='*' && (substr.indexOf('**')!=0) || (substr.indexOf('***')==0)) {
                        index = 0
                    } else if (substr.charAt(substr.length-1)=='*' && 
                    (!(substr.length>=2 && substr.indexOf('**')==substr.length-2) ||
                      (substr.length>=3 && substr.indexOf('***')==substr.length-2)
                    )) {
                        index = substr.length-1
                    } 
                }
                return index
            }
            
            indexOfEnd = checkEndsForStar(afterVal, indexOfEnd)
            indexOfStart = checkEndsForStar(backwardBefore, indexOfStart)
            if (indexOfStart!=-1) {
                indexOfStart = beforeVal.length-1-indexOfStart
            }
        }
        
        if (indexOfStart != -1 && indexOfEnd != -1) {
            const newStart = beforeVal.substring(0,indexOfStart)+beforeVal.substring(indexOfStart+beginning.length)
            const newEnd = afterVal.substring(0,indexOfEnd)+afterVal.substring(indexOfEnd+end.length)
            const newVal = newStart+val.substring(startPos, endPos)+newEnd
            return {val: newVal, newCursorLoc: newStart.length}
        }
        return { val: myField.value, newCursorLoc: undefined }
    }
}

const processSelText = (selText, config) => {
    let {beginning, end, replaceA, replaceB} = config;

    beginning = beginning || '';
    end = end || '';
    replaceA = replaceA || '';
    replaceB = replaceB || '';

    const origSelText = selText;
    switch(config.type) {
        case EInsertType.BEG_END:
            selText = beginning+selText+end
            break;
        case EInsertType.REPLACE:
            const regExA = new RegExp(escapeRegEx(replaceA), 'g');
            selText = selText.replace(regExA, replaceB);

            if(origSelText === selText) {
                //unchanged, try the inversion
                const regExB = new RegExp(escapeRegEx(replaceB), 'g');
                selText = selText.replace(regExB, replaceA);
            }
            break;
        default:
            break;
    }

    return selText;
}

//Returns the index of a string that the cursor is over if it exists
const cursorStringPos = (text, cursorPos, find) => {
    const beginSearchIndex = Math.max(0, cursorPos - find.length);
    const endSearchIndex = Math.min(text.length, cursorPos + find.length)

    const substrPos = text.substring(beginSearchIndex, endSearchIndex).indexOf(find);
    if(substrPos === -1) {
        return -1;
    } 
    return substrPos + beginSearchIndex;
}

export const insertAtCursor = (myField, myValue, config: SpecialCharacter) => {

    let {end, replaceA, replaceB} = config;

    end = end || '';
    replaceA = replaceA || '';
    replaceB = replaceB || '';

    let res = myField.value;
    let textSelected = true
    //IE support
    if (isIESupport()) {
        myField.focus();
        const sel = document['selection'].createRange();
        if (!isEmptyString(myValue)) {
            sel.text = myValue;
        } else {
            if (isEmptyString(myValue)) {
                textSelected = false
            }
            sel.text = processSelText(sel.text, config);
        }
        myValue = sel.text;
    }
    //MOZILLA and others
    else if (isMozilla(myField)) {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        if (startPos==endPos) {
            textSelected = false
            if(config.type === EInsertType.REPLACE) {
                const replaceAPos = cursorStringPos(res, startPos, replaceA);
                if(replaceAPos !== -1) {
                    startPos = replaceAPos;
                    endPos = replaceAPos + replaceA.length;
                } else {
                    const replaceBPos = cursorStringPos(res, startPos, replaceB)
                    if(replaceBPos !== -1) {
                        startPos = replaceBPos;
                        endPos = replaceBPos + replaceB.length;
                    }
                }
            } 
        }
        if (!myValue || myValue=='') {
            myValue = processSelText(res.substring(startPos, endPos),config);
        }
        res = res.substring(0, startPos)
            + myValue
            + res.substring(endPos, res.length);

    } 
    else {
        if (isEmptyString(myValue)) {
            myValue = processSelText(myValue, config);
            textSelected = false
        }
        res += myValue;
    }
    let newPos = startPos+myValue.length;
    if (!textSelected && config.type === EInsertType.BEG_END) {
        newPos -= end.length
    }
    return {value: res, newPos};
}
export const codemirrorInsertAtCursor = (codeMirror:any, insertStart, insertEnd) => {
    //This isn't being used anymore but in case any incompatible symbols come down the line it'll be easy to add here
    const incompatibleSymbols = ['^', '_'];
    const incompatibleSymbolsDict = {"^":{start:"<sup>", end:"</sup>"}, "_":{start:"<sub>", end:"</sub>"}}
    const cursorStart = codeMirror.getCursor('start');
    const cursorEnd = codeMirror.getCursor('end');
    const text = codeMirror.getSelection();
    if(incompatibleSymbols.includes(insertStart)){
        insertEnd = incompatibleSymbolsDict[insertStart].end;
        insertStart = incompatibleSymbolsDict[insertStart].start;
    }
    // Check if text is selected
    if (cursorStart && cursorEnd) {
      // Replace the selected range with your new text
      const newText = insertStart + text + insertEnd;
      codeMirror.replaceRange(newText, cursorStart, cursorEnd);
    }
}